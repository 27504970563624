<template>
  <v-row>
    <v-col cols="12" align="center" dense>
      <v-combobox label="Model" v-model="value" :items="items" variant="underlined" />
    </v-col>
    <v-col align="center" dense>
      <MovementArrive v-if="value == 'Arrive'" />
      <SteeringComponent v-if="value == 'Kinetic'" />
      <MovementWander v-if="value == 'Wander'" />
      <MovementFlock v-if="value == 'Flock'" />
      <PathGame v-if="value == 'Path'" />
    </v-col>

    <v-tooltip activator="parent" location="bottom">
      <div v-if="value == 'Arrive'">
        <p>Please click anywhere on the canvas, and the boid will land and</p>
        <p>stop where you just click.</p>
      </div>
      <div v-if="value == 'Kinetic'">
        <p>Please click anywhere on the canvas. The boid will go to where</p>
        <p>you just click and back to keep moving.</p>
      </div>
      <div v-if="value == 'Wander'">
        <p>Click anywhere inside the canvas to turn on/off the visualization,</p>
        <p>and press any key to change different way to wander.</p>
      </div>
      <div v-if="value == 'Flock'">
        <p>Please click anywhere in canvas to drop a Boid.</p>
        <p>Please drag to drop the Boids continuously.</p>
        <p>It is computational expansive if you drop too many Boids in a canvas.</p>
      </div>
      <div v-if="value == 'Path'">
        <p>The path finding algorithm is using Dijkstra's Shortest Path Algorithm.</p>
        <p>The Boid will follow the path until it get to the end. Once it reach</p>
        <p>the end, the desicision tree will determine the next destination.</p>
        <p>The grey-filled tile is wall. The yellow-filled tile is generated by</p>
        <p>Dijkstra. The red-filled tile is blocked.</p>
        <p>User can click anywhere in the canvas where the tile is not a wall or</p>
        <p>blocked. And the system will look for the shortest path. Then the Boid</p>
        <p>will start path following. If user click on the blocked area Boid will</p>
        <p>teleport and chose random destination.</p>
      </div>
    </v-tooltip>

  </v-row>
</template>
<script>
import MovementArrive from '../components/Demo/MovementArrive.vue'
import SteeringComponent from '../components/Demo/SteeringComponent.vue'
import MovementWander from '../components/Demo/MovementWander.vue'
import MovementFlock from '../components/Demo/MovementFlock.vue'
import PathGame from '../components/Demo/PathGame.vue'
export default {
  name: 'ProjectDemo',
  components: {
    MovementArrive,
    SteeringComponent,
    MovementWander,
    MovementFlock,
    PathGame
  },
  data: () => ({
    items: ['Kinetic', 'Arrive', 'Wander', 'Flock', 'Path'],
    value: 'Kinetic',
  }),

}
</script>
